import { createStore } from 'vuex';

import config from '../config.json';

import HomeScreen from '../views/HomeScreen.vue';
import ChaptersScreen from '../views/ChaptersScreen.vue';
import IntroScreen from '../views/IntroScreen.vue';
import SlideScreens from '../views/SlideScreens.vue';
import ReadyScreen from '../views/ReadyScreen.vue';
import QuestionScreens from '../views/QuestionScreens.vue';
import DoneScreen from '../views/DoneScreen.vue';
import EndScreen from '../views/EndScreen.vue';
import InterruptScreen from '../views/InterruptScreen.vue';
import FeedbackScreen from '../views/FeedbackScreen.vue';

function getChapterDuration(chapter) {
	return `${chapter.duration} | ${config.strings.questionCount.replace(
		'%',
		chapter.questions.length
	)}`;
}

const screens = [];
screens.push({
	id: 'home',
	component: HomeScreen,
	props: {
		...config.home,
		startButton: config.home.startButton || config.strings.startButton,
	},
});

screens.push({
	id: 'chapters',
	component: ChaptersScreen,
	props: {
		startButton: config.strings.startButton,
		chapters: config.chapters.map(chapter => ({
			id: chapter.id,
			style: chapter.style,
			title: chapter.title,
			description: chapter.description,
			threshold:
				chapter.threshold || Math.ceil(chapter.questions.length / 2),
		})),
	},
});

config.chapters.forEach(chapter => {
	screens.push({
		id: `${chapter.id}-intro`,
		component: IntroScreen,
		props: {
			style: chapter.style,
			chapter: chapter.id,
			kicker: chapter.kicker,
			title: chapter.title,
			image: chapter.image,
			description: chapter.description,
			duration: getChapterDuration(chapter),
			startButton: chapter.startButton || config.strings.startButton,
		},
	});

	if (chapter.slides?.length > 0) {
		screens.push({
			id: `${chapter.id}-slides`,
			component: SlideScreens,
			props: {
				style: chapter.style,
				chapter: chapter.id,
				slides: chapter.slides.map(slide => ({
					id: slide.id,
					image: slide.image,
					text: slide.text,
					nextButton: slide.nextButton || config.strings.nextButton,
				})),
			},
		});
	}

	if (chapter.ready || config.ready) {
		screens.push({
			id: `${chapter.id}-ready`,
			component: ReadyScreen,
			props: {
				style: chapter.style,
				chapter: chapter.id,
				title: chapter.ready?.title || config.ready.title,
				description:
					chapter.ready?.description || config.ready.description,
				image: chapter.ready?.image || config.ready.image,
				startButton: chapter.startButton || config.strings.startButton,
			},
		});
	}

	if (chapter.questions?.length > 0) {
		screens.push({
			id: `${chapter.id}-questions`,
			component: QuestionScreens,
			props: {
				style: chapter.style,
				chapter: chapter.id,
				questions: chapter.questions.map(question => ({
					id: question.id,
					text: question.text,
					options: question.options,
					answer: question.answer,
					nextButton:
						question.nextButton || config.strings.nextButton,
				})),
			},
		});
	}

	screens.push({
		id: `${chapter.id}-done`,
		component: DoneScreen,
		props: {
			chapter: chapter.id,
			questions: chapter.questions,
			threshold:
				chapter.threshold || Math.ceil(chapter.questions.length / 2),
			style: chapter.style,
			success: {
				...chapter.success,
				continueButton:
					chapter.success.continueButton ||
					config.strings.continueButton,
			},
			fail: {
				...chapter.fail,
				restartButton:
					chapter.fail.restartButton || config.strings.restartButton,
			},
			menuButton: config.strings.menuButton,
		},
	});

	if (chapter.outro?.length > 0) {
		screens.push({
			id: `${chapter.id}-outro`,
			component: SlideScreens,
			props: {
				style: chapter.style,
				chapter: chapter.id,
				slides: chapter.outro.map(slide => ({
					id: slide.id,
					image: slide.image,
					text: slide.text,
					nextButton: slide.nextButton || config.strings.nextButton,
				})),
			},
		});
	}
});

if (config.interrupt) {
	const index = screens.findIndex(
		screen => screen.id === config.interrupt.after
	);
	screens.splice(index + 1, 0, {
		id: 'interrupt',
		component: InterruptScreen,
		props: {
			...config.interrupt,
			skipButton: config.feedback.skipButton || config.strings.skipButton,
			submitButton:
				config.feedback.submitButton || config.strings.submitButton,
		},
	});
}

screens.push({
	id: 'end',
	component: EndScreen,
	props: {
		...config.end,
		total: config.chapters.reduce(
			(count, chapter) => (count += chapter.questions.length),
			0
		),
		continueButton:
			config.end.continueButton || config.strings.continueButton,
	},
});

screens.push({
	id: 'feedback',
	component: FeedbackScreen,
	props: {
		...config.feedback,
		restartButton:
			config.feedback.restartButton || config.strings.restartButton,
	},
});

export default createStore({
	state: {
		session: '',
		currentSreen: 'home',
		answers: new Map(),
		attempts: new Map(),
		exitConfig: config.exit,
	},
	getters: {
		currentScreen(state) {
			return screens.find(s => s.id === state.currentSreen);
		},
		getAnswer(state) {
			return (chapter, question) =>
				state.answers.get(chapter)?.get(question)?.answer || null;
		},
		getChapterStatus(state) {
			return chapter => {
				const answers = state.answers.get(chapter);
				if (!answers) {
					return false;
				}

				return (
					answers.length ===
					config.chapters.find(c => c.id === chapter).questions.length
				);
			};
		},
		getChapterScore(state) {
			return chapter => {
				if (!state.answers.has(chapter)) {
					return 0;
				}

				const answers = [...state.answers.get(chapter).values()];

				return answers.reduce(
					(score, answer) => (score += answer.correct ? 1 : 0),
					0
				);
			};
		},
		finalScore(state, getters) {
			return [...state.answers.keys()].reduce(
				(score, chapter) => (score += getters.getChapterScore(chapter)),
				0
			);
		},
	},
	mutations: {
		setSession(state, value) {
			state.session = value;
		},
		goToScreen(state, screenId) {
			state.currentSreen = screenId;
		},
		answerQuestion(state, { chapter, question, ...answer }) {
			const allAnswers = state.answers;
			const answers = allAnswers.get(chapter) || new Map();

			answers.set(question, answer);
			allAnswers.set(chapter, answers);

			state.answers = allAnswers;
		},
		resetAnswers(state, chapter) {
			const { answers, attempts } = state;

			answers.delete(chapter);
			attempts.set(chapter, (attempts.get(chapter) ?? -1) + 1);

			state.answers = answers;
			state.attempts = attempts;
		},
	},
	actions: {
		nextScreen({ state, commit }) {
			const index = screens.findIndex(s => s.id === state.currentSreen);
			commit('goToScreen', screens[index + 1].id);
		},
		prevScreen({ state, commit }) {
			const index = screens.findIndex(s => s.id === state.currentSreen);
			commit('goToScreen', screens[index - 1].id);
		},
	},
	modules: {},
});
