<template>
	<AppScreen
		class="ready"
		:style="$attrs.style"
		with-back
		with-close
		@back="prevScreen"
		@close="maybeExit"
	>
		<img :src="image" class="poster" />
		<h1 class="title">{{ title }}</h1>
		<p class="text">{{ description }}</p>
		<div class="screen-buttons">
			<AppButton name="start" :context="context" @click="nextScreen">
				{{ startButton }}
			</AppButton>
		</div>
		<ExitConfirm v-model="showExitConfirm" :context="context" />
	</AppScreen>
</template>

<script>
import { mapActions } from 'vuex';

import ExitConfirm from '../components/ExitConfirm';

export default {
	components: {
		ExitConfirm,
	},
	props: {
		chapter: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			required: true,
		},
		startButton: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			startTime: performance.now(),
			showExitConfirm: false,
		};
	},
	computed: {
		context() {
			return {
				screen: 'ready',
				chapter: this.chapter,
				attempts: this.$store.state.attempts.get(this.chapter),
			};
		},
	},
	methods: {
		...mapActions(['nextScreen', 'prevScreen']),

		maybeExit() {
			this.$log(
				{
					event: 'maybe-exit',
					...this.context,
				},
				this.startTime
			);
			this.showExitConfirm = true;
		},
	},
};
</script>

<style lang="scss">
.ready {
}
</style>
