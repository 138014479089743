<template>
	<div class="progress-bar">
		<div
			v-for="(q, i) in questions"
			:key="i"
			:class="{
				'progress-bar__item': true,
				'is-current': i === index,
			}"
			:data-result="getQuestionStatus(q)"
		>
			<Transition mode="out-in">
				<InlineSVG
					v-if="getQuestionStatus(q) === 'correct'"
					key="correct"
					:src="require('@/assets/progress-correct.svg')"
				/>
				<InlineSVG
					v-else-if="getQuestionStatus(q) === 'incorrect'"
					key="incorrect"
					:src="require('@/assets/progress-incorrect.svg')"
				/>
				<span v-else key="number">{{ i + 1 }}</span>
			</Transition>
		</div>
		<div
			:class="{
				'progress-bar__item': true,
				'progress-bar__star': true,
				'is-current': isComplete,
			}"
		>
			<InlineSVG :src="require('@/assets/progress-complete.svg')" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		index: {
			type: Number,
			default: -1,
		},
		chapter: {
			type: String,
			required: true,
		},
		questions: {
			type: Array,
			required: true,
		},
		isComplete: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState(['answers']),

		getQuestionStatus() {
			return question => {
				const answer = this.answers.get(this.chapter)?.get(question.id);

				if (!answer) {
					return 'unanswerd';
				}

				return answer.correct ? 'correct' : 'incorrect';
			};
		},
	},
};
</script>

<style lang="scss">
.progress-bar {
	display: flex;
	gap: 0.25em;
	margin: 0 auto;

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-primary;
		font-size: 0.875em;
		font-weight: $weight-bold;
		width: 2.3em;
		height: 2.3em;
		border-radius: 50%;
		background: rgba($color-primary-rgb, 0.1) center no-repeat;
		transition: background 0.2s, color 0.2s;

		svg {
			width: 1.14em;
			height: 1.14em;
			fill: currentColor;
		}

		&.is-current,
		&[data-result='correct'] {
			background-color: $color-accent;
			color: $color-secondary;
		}
		&[data-result='incorrect'] {
			background-color: $color-primary;
			color: $color-secondary;
		}
	}

	&__star.is-current {
		background: #deb255;
	}

	.v-enter-active,
	.v-leave-active {
		transition-duration: 0.2s;
	}
}
</style>
