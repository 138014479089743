<template>
	<div :class="classes" :style="vars">
		<AppButton
			v-if="withBack"
			name="back"
			class="back"
			:icon="require('@/assets/back.svg')"
			@click="$emit('back')"
		/>
		<AppButton
			v-if="withClose"
			class="close"
			:icon="require('@/assets/close.svg')"
			@click="$emit('close')"
		/>

		<slot v-if="noInner" />
		<div v-else class="screen-inner">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		class: {
			type: [String, Array, Object],
			default: null,
		},
		style: {
			type: Object,
			default: null,
		},
		noInner: Boolean,
		withBack: Boolean,
		withClose: Boolean,
	},
	emits: ['back', 'close'],
	computed: {
		vars() {
			const vars = {};
			if (this.style) {
				for (const prop in this.style) {
					vars[`--${prop}`] = this.style[prop];
				}
			}
			return vars;
		},
		classes() {
			if (typeof this.class === 'string') {
				return `screen ${this.class}`;
			} else if (this.class instanceof Array) {
				return ['screen', ...this.class];
			} else if (typeof this.class === 'object') {
				return {
					screen: true,
					...this.class,
				};
			}

			return 'screen';
		},
	},
};
</script>

<style lang="scss">
.screen {
	position: fixed;
	inset: 0;
	display: grid;
	place-items: center;
	background: $color-background;
	color: $color-primary;
	overflow: auto;

	.back,
	.close {
		position: absolute;
		z-index: 1;
		top: 0;
		width: 2.5em;
		height: 2.5em;
		margin: 1em;
		border-radius: 50%;
		color: $color-primary;
		transition: background 0.2s, color 0.2s;

		.svg-canvas {
			width: 1.75em;
			height: 1.75em;
			margin: auto;
		}

		&:hover {
			background: $color-primary;
			color: $color-secondary;
		}
	}
	.back {
		left: 0;
	}
	.close {
		right: 0;
	}
}
.screen-inner {
	width: 100%;
	max-width: 45em;
	min-height: 33em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2.5em;
	margin: auto;
}
.screen-buttons {
	display: flex;
	gap: 0.75em;
	margin-top: 1.25em;
}
</style>
