<template>
	<AppScreen class="intro" with-close @close="exit">
		<p class="kicker">{{ kicker }}</p>
		<h1 class="title">{{ title }}</h1>
		<img :src="image" class="poster" />
		<div class="screen-buttons">
			<AppButton @click="start">
				{{ startButton }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
	props: {
		chapter: {
			type: String,
			required: true,
		},
		kicker: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			required: true,
		},
		startButton: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			startTime: performance.now(),
		};
	},
	computed: {
		context() {
			return {
				screen: 'intro',
				chapter: this.chapter,
				attempts: this.$store.state.attempts.get(this.chapter),
			};
		},
	},
	methods: {
		...mapMutations(['goToScreen']),
		...mapActions(['nextScreen']),

		start() {
			this.$log(
				{
					event: 'next',
					...this.context,
				},
				this.startTime
			);
			this.nextScreen();
		},

		exit() {
			this.$log(
				{
					event: 'exit',
					...this.context,
				},
				this.startTime
			);
			this.goToScreen('chapters');
		},
	},
};
</script>

<style lang="scss">
.intro {
}
</style>
