<template>
	<AppScreen class="interrupt" :style="$attrs.style">
		<p class="kicker">{{ kicker }}</p>
		<p class="text">{{ text }}</p>
		<div class="options">
			<AppButton
				v-for="option in options"
				:key="option.id"
				class="option-button"
				is-plain
				@click="choice = option.id"
			>
				<div class="option-icon">
					<Transition>
						<InlineSVG
							v-if="choice === option.id"
							:src="require(`@/assets/button-correct.svg`)"
						/>
					</Transition>
				</div>
				{{ option.text }}
			</AppButton>
		</div>
		<div class="screen-buttons">
			<AppButton v-if="choice !== null" @click="submit">
				{{ submitButton }}
			</AppButton>
			<AppButton
				v-else
				name="skip"
				:context="{ screen: 'interrupt' }"
				@click="nextScreen"
			>
				{{ skipButton }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	props: {
		kicker: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		skipButton: {
			type: String,
			required: true,
		},
		submitButton: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			choice: null,
			startTime: performance.now(),
		};
	},
	methods: {
		...mapActions(['nextScreen']),

		submit() {
			this.$log(
				{
					event: 'submit',
					screen: 'interrupt',
					response: this.choice,
				},
				this.startTime
			);

			this.nextScreen();
		},
	},
};
</script>

<style lang="scss">
.interrupt {
	.kicker {
		[lang='en'] & {
			text-transform: uppercase;
		}
	}

	.text {
		font-size: 1.25em;
		margin: 0.5em auto 1em;
	}
}

.options {
	display: flex;
	width: 100%;
	max-width: 42em;
	justify-content: space-between;
	align-items: flex-start;
	margin: auto;
}

.option-button {
	flex: 1 1 0;
	max-width: 6em;
	margin: 0 auto;
}

.option-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3.5em;
	height: 3.5em;
	background: $color-secondary;
	border-radius: 50%;
	box-shadow: 0 5px 2px rgba(#000, 0.15);
	margin: 0 auto 0.5em;

	.svg-canvas {
		width: 1.75em;
		height: 1.75em;
		margin: auto;
	}
}
</style>
