<template>
	<svg ref="canvas" class="svg-canvas" xmlns="http://www.w3.org/2000/svg" />
</template>

<script>
const CACHE = new Map();

export default {
	props: {
		src: {
			type: String,
			required: true,
		},
	},
	mounted() {
		let source = CACHE.get(this.src);
		if (!source) {
			source = new Promise((resolve, reject) => {
				const request = new XMLHttpRequest();
				request.open('GET', this.src, true);
				request.onload = () => {
					resolve(request.responseXML.documentElement);
				};
				request.onerror = () => reject(request);
				request.send();
			});

			CACHE.set(this.src, source);
		}

		const { canvas } = this.$refs;
		source.then(svg => {
			canvas.setAttribute('width', svg.getAttribute('width'));
			canvas.setAttribute('height', svg.getAttribute('height'));
			canvas.setAttribute('viewBox', svg.getAttribute('viewBox'));

			canvas.innerHTML = svg.innerHTML;
		});
	},
};
</script>

<style lang="scss">
.svg-canvas {
	display: block;

	path {
		fill: currentColor;
	}
}
</style>
