<template>
	<Component
		:is="tag"
		:type="tag === 'button' ? type : undefined"
		:class="{
			button: !isPlain && !icon,
			'is-next': isNext,
		}"
		v-bind="attrs"
		@click="onClick"
	>
		<InlineSVG v-if="icon" :src="icon" />
		<slot v-else-if="isPlain" />
		<span v-else><slot /></span>
	</Component>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			default: '',
		},
		context: {
			type: Object,
			default: null,
		},
		icon: {
			type: String,
			default: '',
		},

		// Semantics
		type: {
			type: String,
			default: 'button',
		},
		href: {
			type: String,
			default: '',
		},
		newTab: Boolean,
		download: Boolean,

		// Styles
		isPlain: Boolean,
		isNext: Boolean,
	},
	emits: ['click'],
	data() {
		return {
			startTime: performance.now(),
		};
	},
	computed: {
		tag() {
			return this.href ? 'a' : 'button';
		},
		attrs() {
			return this.href
				? {
						href: this.href,
						target: this.newTabe ? '_blank' : undefined,
						download: this.download ? '' : undefined,
				  }
				: {};
		},
	},
	methods: {
		onClick(event) {
			if (this.name) {
				this.$log(
					{
						event: this.name,
						...this.context,
					},
					this.startTime
				);
			}

			this.$emit('click', event);
		},
	},
};
</script>

<style lang="scss">
button {
	background: transparent;
	font: inherit;
	padding: 0;
	border: none;
	cursor: pointer;
}
.button {
	$button-color: var(--button-color, $color-primary);
	$button-contrast: var(--button-contrast, $color-secondary);
	font-weight: $weight-regular;
	min-width: 2.8em;
	min-height: 2.8em;
	border: 4px solid $button-color;
	border-radius: 1em;
	padding: 0 2em;
	background: $button-color;
	color: $button-contrast;
	transition: background 0.2s, color 0.2s;

	&:hover {
		background: transparent;
		color: $button-color;
	}

	[lang='en'] & {
		text-transform: uppercase;
	}
}
</style>
