<template>
	<AppScreen class="end">
		<img :src="image" class="poster" />
		<p class="score">{{ finalScore }} / {{ total }}</p>
		<h1 class="title">{{ title }}</h1>
		<p class="text">{{ description }}</p>
		<div class="screen-buttons">
			<AppButton
				name="continue"
				:context="{ screen: 'end' }"
				@click="nextScreen"
			>
				{{ continueButton }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			required: true,
		},
		total: {
			type: Number,
			required: true,
		},
		continueButton: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState(['attempts']),
		...mapGetters(['finalScore']),
	},
	mounted() {
		const attempts = [...this.attempts.values()].reduce(
			(count, total) => (total += count),
			0
		);

		this.$log('complete', {
			finalScore: this.finalScore,
			retries: attempts - this.attempts.size,
		});
	},
	methods: {
		...mapActions(['nextScreen']),
	},
};
</script>

<style lang="scss">
.end {
}
</style>
