<template>
	<div class="question-interface">
		<p class="question-text">{{ text }}</p>
		<div class="answer-buttons">
			<AppButton
				v-for="option in options"
				:key="option.id"
				:class="{
					'answer-button': true,
					'player-answer': option.id === choice,
					'correct-answer': choice && option.id === answer,
				}"
				:disabled="!!choice"
				is-plain
				@click="selectAnswer(option.id)"
			>
				<Transition>
					<InlineSVG
						v-if="choice"
						:src="
							require(`@/assets/button-${getResult(
								option.id
							)}.svg`)
						"
					/>
				</Transition>
				{{ option.text }}
			</AppButton>
		</div>
		<div class="screen-buttons">
			<AppButton
				class="question-confirm"
				name="next"
				:context="context"
				:disabled="!choice"
				@click="$emit('next')"
			>
				{{ nextButton }}
			</AppButton>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		chapter: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		answer: {
			type: String,
			required: true,
		},
		nextButton: {
			type: String,
			required: true,
		},
	},
	emits: ['next'],
	data() {
		return {
			startTime: performance.now(),
		};
	},
	computed: {
		context() {
			return {
				screen: 'questions',
				chapter: this.chapter,
				attempts: this.$store.state.attempts.get(this.chapter),
				question: this.id,
			};
		},

		choice() {
			return this.$store.getters.getAnswer(this.chapter, this.id);
		},
		getResult() {
			return option => (option === this.answer ? 'correct' : 'incorrect');
		},
	},
	methods: {
		selectAnswer(answer) {
			this.$store.commit('answerQuestion', {
				chapter: this.chapter,
				question: this.id,
				answer,
				correct: answer === this.answer,
			});

			this.$log(
				{
					event: 'answer',
					...this.context,
					answer,
					correct: answer === this.answer,
				},
				this.startTime
			);
		},
	},
};
</script>

<style lang="scss">
.question-interface {
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.question-text {
	font-size: 1.25em;
	margin: auto;
}
.answer-buttons {
	width: 100%;
	max-width: 20em;
}
.answer-button {
	display: block;
	position: relative;
	width: 100%;
	background: $color-secondary;
	color: $color-primary;
	border: none;
	line-height: 1.2;
	padding: 0.9em 2.5em;
	border-radius: 1em;
	box-shadow: 0 5px 2px rgba(#000, 0.15);
	cursor: pointer;
	transition: background 0.2s, color 0.2s;

	&:not(:first-child) {
		margin-top: 0.5em;
	}

	svg {
		position: absolute;
		width: 1em;
		height: 1em;
		top: 50%;
		left: 1.125em;
		margin-top: -0.5em;
		fill: $color-primary;
	}

	&:not(:disabled):hover {
		background: $color-accent;
		color: #fff;
	}

	&.player-answer svg {
		fill: $color-accent;
	}
}
.question-confirm {
	&:disabled {
		visibility: hidden;
	}
}
</style>
