<template>
	<AppScreen
		class="question"
		:style="$attrs.style"
		with-close
		@close="showExitConfirm = true"
	>
		<ProgressBar :chapter="chapter" :questions="questions" :index="index" />
		<Transition mode="out-in">
			<QuestionInterface
				:key="question.id"
				:chapter="chapter"
				v-bind="question"
				@next="advance"
			/>
		</Transition>
		<ExitConfirm v-model="showExitConfirm" :context="context" />
	</AppScreen>
</template>

<script>
import { mapActions } from 'vuex';

import ProgressBar from '../components/ProgressBar';
import QuestionInterface from '../components/QuestionInterface';
import ExitConfirm from '../components/ExitConfirm';

export default {
	components: {
		ProgressBar,
		QuestionInterface,
		ExitConfirm,
	},
	props: {
		chapter: {
			type: String,
			required: true,
		},
		questions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			index: 0,
			startTime: performance.now(),
			showExitConfirm: false,
		};
	},
	computed: {
		question() {
			return this.questions[this.index];
		},
		context() {
			return {
				screen: 'questions',
				chapter: this.chapter,
				attempts: this.$store.state.attempts.get(this.chapter),
				question: this.question.id,
			};
		},
	},
	watch: {
		question() {
			this.startTime = performance.now();
		},
	},
	methods: {
		...mapActions(['nextScreen']),

		advance() {
			if (this.index < this.questions.length - 1) {
				this.index++;
			} else {
				this.nextScreen();
			}
		},
	},
};
</script>
