<template>
	<AppScreen class="complete">
		<ProgressBar :chapter="chapter" :questions="questions" is-complete />
		<img :src="data.image" class="poster" />
		<p class="score">{{ score }} / {{ total }}</p>
		<h1 class="title">{{ data.title }}</h1>
		<p class="text">{{ data.description }}</p>
		<div class="screen-buttons">
			<AppButton
				v-if="passed"
				name="continue"
				:context="context"
				@click="nextScreen"
			>
				{{ data.continueButton }}
			</AppButton>
			<AppButton
				v-else
				name="restart"
				:context="context"
				@click="resetChapter"
			>
				{{ data.restartButton }}
			</AppButton>
			<AppButton
				name="menu"
				:context="context"
				@click="goToScreen('chapters')"
			>
				{{ menuButton }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import ProgressBar from '../components/ProgressBar';

export default {
	components: {
		ProgressBar,
	},
	props: {
		chapter: {
			type: String,
			required: true,
		},
		questions: {
			type: Array,
			required: true,
		},
		threshold: {
			type: Number,
			required: true,
		},
		success: {
			type: Object,
			required: true,
		},
		fail: {
			type: Object,
			required: true,
		},
		menuButton: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState(['attempts']),
		...mapGetters(['getChapterScore']),

		total() {
			return this.questions.length;
		},
		score() {
			return this.getChapterScore(this.chapter);
		},
		passed() {
			return this.score >= this.threshold;
		},
		data() {
			return this.passed ? this.success : this.fail;
		},

		context() {
			return {
				screen: 'done',
				chapter: this.chapter,
				attempts: this.attempts.get(this.chapter),
				passed: this.passed,
			};
		},
	},
	mounted() {
		this.$log({
			event: 'review',
			...this.context,
		});
	},
	methods: {
		...mapMutations(['resetAnswers', 'goToScreen']),
		...mapActions(['nextScreen']),

		resetChapter() {
			this.resetAnswers(this.chapter);
			this.goToScreen(`${this.chapter}-questions`);
		},
	},
};
</script>

<style lang="scss">
.complete {
}
</style>
