<template>
	<Transition mode="out-in">
		<Component
			:is="currentScreen.component"
			:key="currentScreen.id"
			v-bind="currentScreen.props"
		/>
	</Transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: mapGetters(['currentScreen']),
};
</script>

<style lang="scss">
* {
	box-sizing: inherit;
	margin: 0;
}

html {
	box-sizing: border-box;
}

body {
	margin: 0;
	background: $color-background;
	color: $color-primary;
	font-family: Manrope, Helvetica, Arial, sans-serif;
	font-size: clamp(rem(12), 2vh, rem(22));
	line-height: 1.3;
	text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@media screen and (max-width: 30em) {
		font-size: clamp(rem(12), 1.5vh, rem(22));
	}
}

h1,
h2 {
	font-size: 3.5em;
	font-weight: $weight-bold;
	line-height: 1.2;
}

a {
	color: inherit;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

.score,
.kicker {
	margin-bottom: 0.2em;
}

.title {
	margin-bottom: 0.2em;
}

.text {
	white-space: pre-wrap;
}

.poster {
	margin: 2em auto;
	object-fit: contain;
	max-height: min(500px, 50vh);
}

.text {
	margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.4s;
}
.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
