<template>
	<AppScreen class="feedback" @close="goToScreen('chapters')">
		<img :src="image" class="poster" />
		<h1 class="title">{{ title }}</h1>
		<p class="text">{{ description }}</p>
		<div ref="widget" class="widget-wrap" />
		<div class="screen-buttons">
			<AppButton
				name="restart"
				:context="{ screen: 'feedback' }"
				@click="goToScreen('chapters')"
			>
				{{ restartButton }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
import { mapMutations } from 'vuex';
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

export default {
	props: {
		formId: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			required: true,
		},
		restartButton: {
			type: String,
			required: true,
		},
	},
	mounted() {
		createWidget(this.formId, {
			container: this.$refs.widget,
			hideHeaders: true,
			hideFooter: true,
		});
	},
	methods: {
		...mapMutations(['goToScreen']),
	},
};
</script>

<style lang="scss">
.feedback {
}
.widget-wrap {
	flex: none;
	width: 100%;
	height: 36em;
	margin-top: 2em;
}
</style>
