import { createApp } from 'vue';
import App from './App.vue';

import AppScreen from './components/AppScreen';
import AppButton from './components/AppButton';
import InlineSVG from './components/InlineSVG';

import store from './store';
import logging from './mixins/logging';
import config from './config.json';

const LOGGING_URL = process.env.VUE_APP_LOGGING_URL || process.env.LOGGING_URL;

const dom = document.getElementById('app');
if (config.style) {
	for (const prop in config.style) {
		dom.style.setProperty(`--${prop}`, config.style[prop]);
	}
}

createApp(App)
	.component('AppScreen', AppScreen)
	.component('AppButton', AppButton)
	.component('InlineSVG', InlineSVG)
	.use(store)
	.use(logging, LOGGING_URL, store)
	.mount(dom);
