<template>
	<AppScreen
		class="slide"
		:style="$attrs.style"
		no-inner
		with-back
		with-close
		@back="backtrack"
		@close="showExitConfirm = true"
	>
		<Transition mode="out-in">
			<div :key="index" class="screen-inner">
				<img :src="slide.image" class="poster" />
				<p class="text">{{ slide.text }}</p>
				<div class="screen-buttons">
					<AppButton @click="advance">
						{{ slide.nextButton }}
					</AppButton>
				</div>
			</div>
		</Transition>
		<ExitConfirm v-model="showExitConfirm" :context="context" />
	</AppScreen>
</template>

<script>
import { mapActions } from 'vuex';

import ExitConfirm from '../components/ExitConfirm';

export default {
	components: {
		ExitConfirm,
	},
	props: {
		chapter: {
			type: String,
			required: true,
		},
		slides: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			index: 0,
			startTime: performance.now(),
			showExitConfirm: false,
		};
	},
	computed: {
		slide() {
			return this.slides[this.index];
		},
		context() {
			return {
				screen: 'slides',
				chapter: this.chapter,
				attempts: this.$store.state.attempts.get(this.chapter),
				slide: this.slide.id,
			};
		},
	},
	watch: {
		slide() {
			this.startTime = performance.now();
		},
	},
	methods: {
		...mapActions(['nextScreen', 'prevScreen']),

		advance() {
			this.$log(
				{
					event: 'next',
					...this.context,
				},
				this.startTime
			);

			if (this.index < this.slides.length - 1) {
				this.index++;
			} else {
				this.nextScreen();
			}
		},

		backtrack() {
			this.$log(
				{
					event: 'back',
					...this.context,
				},
				this.startTime
			);

			if (this.index > 0) {
				this.index--;
			} else {
				this.prevScreen();
			}
		},
	},
};
</script>

<style lang="scss">
.slide {
}
</style>
