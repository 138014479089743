<template>
	<Transition>
		<AppScreen v-if="modelValue" class="exit">
			<h1 class="exit__title">{{ exitConfig.title }}</h1>
			<p class="exit__text">{{ exitConfig.description }}</p>
			<div class="exit__buttons">
				<AppButton @click="$emit('update:modelValue', false)">
					{{ exitConfig.resumeButton }}
				</AppButton>
				<AppButton
					name="quit chapter"
					:context="context"
					@click="goToScreen('chapters')"
				>
					{{ exitConfig.confirmButton }}
				</AppButton>
			</div>
		</AppScreen>
	</Transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
	inheritAttrs: false,
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		context: {
			type: Object,
			required: true,
		},
	},
	emits: ['update:modelValue'],
	computed: mapState(['exitConfig']),
	methods: mapMutations(['goToScreen']),
};
</script>

<style lang="scss">
.exit {
	background: $color-primary;
	color: $color-secondary;

	&__title {
		margin: auto auto 0;
	}
	&__text {
		margin: 0 auto auto;
	}
	&__buttons {
		display: flex;
		gap: 0.75em;
	}

	.button {
		--button-color: #{$color-secondary};
		--button-contrast: #{$color-primary};
	}
}
</style>
