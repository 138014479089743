<template>
	<AppScreen class="chapters">
		<AppButton
			v-for="(chapter, index) in chapters"
			:key="chapter.id"
			:class="{
				'chapter-button': true,
				'is-unlocked': isChapterUnlocked(index),
				'is-completed': isChapterCompleted(index),
			}"
			is-plain
			:disabled="!isChapterUnlocked(index)"
			@click="startChapter(chapter.id)"
		>
			<div class="chapter-button__icon">
				<Transition mode="out-in">
					<span v-if="isChapterUnlocked(index)" key="number">
						{{ index + 1 }}
					</span>
					<InlineSVG
						v-else-if="isChapterCompleted(index)"
						key="check"
						:src="require('@/assets/check.svg')"
					/>
					<InlineSVG
						v-else
						key="lock"
						:src="require('@/assets/lock.svg')"
					/>
				</Transition>
			</div>
			<div class="chapter-button__label">
				{{ chapter.title }}
				<template v-if="chapter.description">
					<br />
					<small>{{ chapter.description }}</small>
				</template>
			</div>
		</AppButton>
	</AppScreen>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
	props: {
		chapters: {
			type: Array,
			required: true,
		},
		startButton: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			selected: null,
			startTime: performance.now(),
		};
	},
	computed: {
		...mapGetters(['getChapterStatus', 'getChapterScore']),
	},
	methods: {
		...mapMutations(['goToScreen', 'resetAnswers']),

		isChapterUnlocked(index) {
			if (index === 0) {
				return true;
			}

			const chapter = this.chapters[index - 1];
			const score = this.getChapterScore(chapter.id);

			return score && score >= chapter.threshold;
		},

		isChapterCompleted(index) {
			return this.getChapterStatus(this.chapters[index].id);
		},

		startChapter(chapter) {
			this.resetAnswers(chapter);
			this.goToScreen(`${chapter}-intro`);

			this.$log(
				{
					event: 'select chapter',
					screen: 'menu',
					chapter: chapter,
					attempts: this.$store.state.attempts.get(chapter),
				},
				this.startTime
			);
		},
	},
};
</script>

<style lang="scss">
.chapters {
	background: $color-accent;
	color: $color-secondary;
}

.chapter-button {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 1.25em;
	text-align: left;
	padding: 1em;
	background: transparent;
	color: inherit;
	border: none;
	border-radius: 0.8em;

	& + * {
		margin-top: 0.25em;
	}

	&:disabled {
		cursor: not-allowed;
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.25em;
		height: 2.25em;
		font-weight: $weight-bold;
		margin-right: 0.8em;
		border-radius: 0.8em;
		background: $color-secondary;
		color: $color-primary;

		.svg-canvas {
			width: 1.4em;
			height: 1.4em;
		}
	}

	&__label {
		small {
			font-size: 0.8em;
		}
	}

	&.is-unlocked {
		cursor: pointer;
	}

	&.is-unlocked:hover {
		background: $color-secondary;
		color: $color-primary;
	}
	&.is-unlocked:hover &__icon {
		background: $color-primary;
		color: $color-secondary;
	}
}
</style>
