import { fetch } from 'whatwg-fetch';
import { v4 as uuid } from 'uuid';

function encrypt( message ) {
	const source_characters = 'abcdefghijklmnopqrstuvwxyz0123456789'.split( '' );
	const target_characters = 'g1xcou5bnklir28djv3sqwyhfm409z6tap7e'.split( '' );
	const zip = ( a, b ) => a.map( ( k, i ) => [ k, b[ i ] ] );
	const cipher = Object.fromEntries( zip( source_characters, target_characters ) );
	return message.replace( /[a-z0-9]/g, c => cipher[ c ] );
}

function saveData(key, value, ttl) {
	localStorage.setItem(
		key,
		JSON.stringify({
			value,
			expires: Date.now() + ttl,
		})
	);
}

function loadData(key) {
	const json = localStorage.getItem(key);

	if (!json) {
		return null;
	}

	const item = JSON.parse(json);
	if (Date.now() > item.expires) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
}

class Logger {
	constructor(apiURL = '') {
		this.apiURL = apiURL;
	}

	async start() {
		this.sessionId = loadData('sessionId');
		if (!this.sessionId) {
			this.sessionId = uuid();
			saveData('sessionId', this.sessionId, 1000 * 60 * 60 * 24 * 28);
		}

		this.send({
			event: 'load',
			screen: 'home',
		});

		return Promise.resolve(this.sessionId);
	}

	async send(payload, startTime = null) {
		if (!this.apiURL) {
			return Promise.resolve({});
		}

		payload.session_id = this.sessionId;
		payload.duration_in_sec = startTime
			? Math.round(performance.now() - startTime) / 1000
			: 0;

		try {
			const params = new URLSearchParams(payload);
			const encrypted_payload = encrypt(params.toString())
			const url = `${this.apiURL}?cn=sessions&${encrypted_payload}`;

			fetch(url);
		} catch (exception) {
			console.error(exception);
		}
	}
}

export default {
	install(app, apiURL, store) {
		const logger = new Logger(apiURL);

		logger.start().then(session => {
			store.commit('setSession', session);
		});

		app.config.globalProperties.$log = function () {
			logger.send(...arguments);
		};
	},
};
