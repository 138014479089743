<template>
	<AppScreen class="home">
		<p class="kicker">{{ kicker }}</p>
		<h1 class="title">{{ title }}</h1>
		<img :src="image" class="poster" />
		<p v-if="description" class="text">{{ description }}</p>
		<div class="screen-buttons">
			<AppButton
				name="start-game"
				:context="{ screen: 'home' }"
				@click="goToScreen('chapters')"
			>
				{{ startButton }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
	props: {
		kicker: {
			type: String,
			default: null,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			default: null,
		},
		image: {
			type: String,
			default: null,
		},
		startButton: {
			type: String,
			required: true,
		},
	},
	methods: mapMutations(['goToScreen']),
};
</script>

<style lang="scss">
.home {
	.text {
		font-size: 1.25em;
	}

	.kicker {
		[lang='en'] & {
			text-transform: uppercase;
		}
	}
}
</style>
